import Home from '../views/Home.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
},
{
    path: '/login',
    name: 'Login',
    component: () =>
        import( /* webpackChunkName: "login" */ '../views/Auth/Login.vue')
},
{
    path: '/register',
    name: 'Register',
    component: () =>
        import( /* webpackChunkName: "register" */ '../views/Auth/Register.vue')
},
{
    path: '/verify/:path/:number',
    name: 'Verify',
    component: () =>
        import( /* webpackChunkName: "verify" */ '../views/Auth/OTP.vue')
},
{
    path: '/signup',
    redirect: "/signup/step-1",
    name: 'Signup',
    component: () =>
        import('../views/Auth/Signup/Signup.vue'),
    children: [{
        path: '/signup/step-1',
        name: 'step-1',
        component: () =>
            import('../views/Auth/Signup/SignupStep1.vue')
    },
    {
        path: '/signup/step-2',
        name: 'step-2',
        component: () =>
            import('../views/Auth/Signup/SignupStep2.vue')
    },
    {
        path: '/signup/step-3',
        name: 'step-3',
        component: () =>
            import('../views/Auth/Signup/SignupStep3.vue')
    },
    {
        path: '/signup/step-4',
        name: 'step-4',
        component: () =>
            import('../views/Auth/Signup/SignupStep4.vue')
    }
    ]
},
{
    path: '/footer',
    redirect: "/",
    name: 'Footer-Pages',
    component: () =>
        import('../views/Footer/FooterPages.vue'),
    children: [{
    path: '/about-us',
    name: 'about-us',
    component: () =>
        import('../views/Footer/AboutUs.vue')
},
{
    path: '/faqs',
    name: 'faqs',
    component: () =>
        import('../views/Footer/FAQs.vue')
},
{
    path: '/contact-us',
    name: 'contact-us',
    component: () =>
        import('../views/Footer/ContactUs.vue')
},
{
    path: '/cookies-policy',
    name: 'cookies-policy',
    component: () =>
        import('../views/Footer/CookiesPolicy.vue')
},
{
    path: '/products',
    name: 'products',
    component: () =>
        import('../views/Footer/Product.vue')
},{
    path: '/terms-and-condition',
    name: 'terms-and-condition',
    component: () =>
        import('../views/Footer/TnC.vue')
},
{
    path: '/privacy',
    name: 'privacy',
    component: () =>
        import('../views/Footer/Privacy.vue')
},
{
    path: '/return-policy',
    name: 'return-policy',
    component: () =>
        import('../views/Footer/ReturnPolicy.vue')
},
{
    path: '/disclaimer',
    name: 'disclaimer',
    component: () =>
        import('../views/Footer/Disclaimer.vue')
},
{
    path: '/shipping-policy',
    name: 'shipping-policy',
    component: () =>
        import('../views/Footer/ShippingPolicy.vue')
},],
},

{
    path: '/signup-complete',
    name: 'Signup-complete',
    component: () =>
        import('../views/Auth/SignupComplete.vue')
},

{
    path: '/layout',
    name: 'Layout',
    redirect: '/dashboard',
    component: () =>
        import('../views/Layout.vue'),
    children: [{
        path: '/dashboard',
        name: 'Dashboard',
        component: () =>
            import('../views/Dashboard.vue')
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () =>
            import('../views/Profile.vue')
    },
    {
        path: '/vaccine-history',
        name: 'VaccineHistory',
        component: () =>
            import('../views/VaccineHistory.vue')
    },
    {
        path: '/health-information',
        name: 'HealthInformation',
        component: () =>
            import('../views/HealthInformation.vue')
    },
    {
        path: '/qr-code',
        name: 'QRCode',
        component: () =>
            import('../views/QRCode.vue')
    },
    {
        path: '/vaccine-card',
        name: 'VaccineCard',
        component: () =>
            import('../views/VaccineCard.vue')
    },
    
    ]
},
{
    path: '/upload-file',
    name: 'DocUpload',
    component: () =>
        import('../views/DocUpload.vue')
},
{
    path: '/health-info-file-upload',
    name: 'HealthInfoDocUpload',
    component: () =>
        import('../views/HealthInfoDocUpload.vue')
},
{
    path: '/upload-success',
    name: 'DocUploadSuccess',
    component: () =>
        import('../views/DocUploadSuccess.vue')
},
{
    path: '/forgot-password',
    name: 'Forgot-password',
    component: () =>
        import('../views/Auth/ForgotPassword.vue')
},
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router