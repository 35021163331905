<template>
  <footer id="footer" class="footer shape-section">
        <div class="section-shape section-shape-top">
            <svg xmlns="http://www.w3.org/2000/svg" width="1919.943" height="175.015" viewBox="0 0 1919.943 175.015">
                <g transform="translate(0 -1542.985)">
                    <g transform="translate(0 -16)">
                        <g transform="translate(0 1558.985)">
                            <path
                                d="M60.288,2764.084s318.529-177.236,970.329-17.017c602.353,148.064,932.609-6.69,949.61-107.067-.447-.863-19.058,36.166-96.006,71.687-108.614,50.138-341.341,96.942-795.6,3-185.19-43.732-383.642-62.013-516-62.192C188.2,2651.975,60.288,2764.084,60.288,2764.084Z"
                                transform="translate(-60.284 -2639.985)" fill="#015eab" />
                            <path
                                d="M61.057,2586s101.5-55,265.337-76.486c103.715-21.472,428.136-15.881,759.9,57.412,3.427.757,162.448,31.52,257.47,42.443,73.8,8.484,144.288,10.261,183.134,10.821,370.408,5.34,454.1-158.149,454.1-158.149l0,174.96H61.057Z"
                                transform="translate(-61.057 -2461.985)" fill="#fff" />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <div class="footer-container">
            <div class="footer-top">
                <div class="container footer-top-container">
                    <div class="footer-logo mb-4">
                        <a href="/"><img src="../assets/images/footer-logo.png" alt="Vaxtrax Global" class="img-fluid"></a>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-sm-6 mb-sm-0 footer-contact">
                            <p>
                                <strong>JAB COMPANIES, INC</strong><br>1405 Silver Lake Road NW<br>St. Paul, MN 55112
                                <br><br>
                                <strong>PH:</strong> <a href="tel:16123380794" class="text-decoration-none">+1 612-338-0794</a><br>
                                <strong>MB:</strong> <a href="tel:16125008805" class="text-decoration-none">+1 612-500-8805</a><br>
                                <strong>EMAIL:</strong> <a href="mailto:info@vaxtraxglobal.com" class="text-decoration-none">info@vaxtraxglobal.com</a>
                            </p>
                        </div>
                        <div class="col-lg-4 col-sm-6 mb-sm-0 footer-links">
                            <h4>Useful Links</h4>
                            <div class="row">
                                <div class="col-sm-6 mb-1 mb-sm-0">
                                    <ul>
                                        <li><i class="fas fa-chevron-right fa-1x text-primary mr-1"></i><router-link class="text-decoration-none" to="/" title="Home" >Home </router-link></li>
                                        <li><i class="fas fa-chevron-right fa-1x text-primary mr-1"></i><router-link class="text-decoration-none" to="/about-us" title="About Us">About Us</router-link></li>
                                        <li><i class="fas fa-chevron-right fa-1x text-primary mr-1"></i><router-link class="text-decoration-none" to="/products" title="Products">Products</router-link></li>
                                        <li><i class="fas fa-chevron-right fa-1x text-primary mr-1"></i><router-link class="text-decoration-none" to="#" title="Pricing">Pricing</router-link></li>
                                        <li><i class="fas fa-chevron-right fa-1x text-primary mr-1"></i><router-link class="text-decoration-none" to="/faqs" title="FAQ's">FAQ's</router-link></li>
                                    </ul>
                                </div>
                                <div class="col-sm-6">
                                    <ul>
                                        <li><i class="fas fa-chevron-right fa-1x text-primary mr-1"></i><router-link class="text-decoration-none" to="/shipping-policy" title="Shipping Policy">Shipping Policy</router-link></li>
                                        <li><i class="fas fa-chevron-right fa-1x text-primary mr-1"></i><router-link class="text-decoration-none" to="/disclaimer" title="Disclaimer">Disclaimer</router-link></li>
                                        <li><i class="fas fa-chevron-right fa-1x text-primary mr-1"></i><router-link class="text-decoration-none" to="/return-policy" title="Return Policy">Return Policy</router-link></li>
                                        <li><i class="fas fa-chevron-right fa-1x text-primary mr-1"></i><router-link class="text-decoration-none" to="/privacy" title="Privacy">Privacy</router-link></li>
                                        <li><i class="fas fa-chevron-right fa-1x text-primary mr-1"></i><router-link class="text-decoration-none" to="/terms-and-condition" title="Terms & Conditions">Terms &
                                    Conditions</router-link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 footer-newsletter mt-4 mt-lg-0">
                            <h4>Join Our Newsletter</h4>
                            <p>Subscribe our newsletter to receive the exclusive offers every week.</p>
                            <!-- Begin Mailchimp Signup Form -->
                            <div id="mc_embed_signup">
                                <form action="https://vaxtraxglobal.us20.list-manage.com/subscribe/post?u=dcff957c78f0740fd2863e687&amp;id=9be0d18de8" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                                    <div id="mc_embed_signup_scroll">
                                        <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required>
                                        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                        <div style="position: absolute; left: -5000px;" aria-hidden="true">
                                            <input type="text" name="b_dcff957c78f0740fd2863e687_9be0d18de8" tabindex="-1" value="">
                                        </div>
                                        <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
                                    </div>
                                </form>
                            </div>
                            <!--End mc_embed_signup-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container py-3">
                    <div class="row">
                        <div class="col-md-4 mr-md-auto text-center text-md-start align-self-center text-md-left order-2 order-md-1 mb-3 mb-md-0">
                            <div class="copyright">
                                &copy; Copyright <strong><span>VAXTRAX GLOBAL</span></strong>. All Rights Reserved
                            </div>
                        </div>
                        <div class="col-md-4 order-3 order-md-2 text-center align-self-center "><img src="../assets/images/jab-icon.png" alt="JAB" class="img-fluid"></div>
                        <div class="col-md-4 social-links text-center text-md-end order-1 mb-3 order-md-3 mb-md-0">
                            <a href="https://www.facebook.com/profile.php?id=100067807267680" class="facebook mr-1" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="https://twitter.com/vaxtraxgb" class="twitter mr-1 ml-1" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="https://www.instagram.com/vaxtraxgb/" class="instagram mr-1 ml-1" target="_blank"><i class="fab fa-instagram-square"></i></a>
                            <a href="https://www.youtube.com/channel/UCasKO9fy0ZSV8cENbmPLRgw/featured" class="youtube ml-1" target="_blank"><i class="fab fa-youtube"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
// import "@fortawesome/fontawesome-free/css/all.css";
// import "@fortawesome/fontawesome-free/js/all.js";
// import "../VAXTRAX Global_files/html/assets/js/wow.js";
// import "../VAXTRAX Global_files/html/assets/js/venobox.min.js";
// import "../VAXTRAX Global_files/html/assets/js/validate.js";
// import "../VAXTRAX Global_files/html/assets/js/global.js";
export default {};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
.footer{
  font-family: "Raleway", sans-serif;
}
// @import "../VAXTRAX Global_files/html/assets/css/screen.css";

// @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
</style>