<template>
  <div class="home">
    <div class="se-pre-con" id="loadingDiv">
      <img src="@/assets/Preloader_7.gif" alt="" />
    </div>
    <div>
      <!-- <button type="button" class="mobile-nav-toggle d-lg-none">
        <b-icon icon="list" aria-hidden="true"></b-icon>
      </button> -->

      <!-- ! Wrapper Begin -->
      <div class="wrapper">
        <!-- ! Header Begin -->

        <Navbar />

        <!-- ! Header End -->
        <!-- ! Main Begin -->
        <main id="main" class="main">
          <section class="home-banner-section">
            <div class="home-banner-video-container">
              <video
                class="homevideo"
                id="homevideo"
                autoplay=""
                loop=""
                muted=""
                autobuffer=""
                playsinline=""
              >
                <source src="@/assets/videos/course-video.mp4" />
              </video>
            </div>
            <div class="banner-caption">
              <div class="banner-caption-content text-white">
                <div
                  class="
                    banner-caption-text
                    d-flex
                    w-100
                    align-items-center
                    flex-warp
                    h-100
                    py-5
                  "
                >
                  <div class="container">
                    <div class="banner-text">
                      <h1 class="title-border">
                        VTG <br /><span>Plateform Portability</span>
                      </h1>
                      <div class="mb-4">
                        <p>Your COVID Tests and Vaccines Records</p>
                      </div>
                      <div class="banner-btn">
                        <a
                          href="#vtg_app"
                          class="btn btn-orange btn-get-started scrollto"
                          title="Download App"
                          >Download App<i class="mx-2 fas fa-arrow-right"></i
                        ></a>
                        <a
                          href="https://www.youtube.com/watch?v=1jpiqDR-dyk"
                          class="venobox btn-watch-video vbox-item"
                          data-vbtype="video"
                          data-autoplay="true"
                          style="text-decoration: none"
                        >
                          Watch Video
                          <span class="icon-paly"
                            ><i class="mx-2 fas fa-play"></i></span
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="curved-section-main">
            <!-- ! Section start -->
            <section
              class="section curved-section settion-portability"
              id="heroBG"
            >
              <div class="section-shape section-shape-top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1920.016"
                  height="192.536"
                  viewBox="0 0 1920.016 192.536"
                  class="shape-1"
                >
                  <path
                    d="M1.819,193.5l1920,.267s.016-67.509.016-142.375C1410.485-139.008,927.722,282.6,1.819,162.6Z"
                    transform="translate(-1.819 -1.231)"
                  ></path>
                </svg>
              </div>
              <div class="container section-container-padding">
                <div class="section-top-bar d-flex">
                  <div class="section-top-bar-container">
                    <h2 class="section-title">
                      VTG Portability Platform for
                      <span>Your Covid Tests & Vaccines</span>
                    </h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-lg-4">
                    <ul class="custom-list">
                      <li>Unique QR Code</li>
                      <li>Digital and Physical Card</li>
                      <li>Visa Sticker</li>
                      <li>IOS, Android and Web</li>
                      <li>Tracing, Quarantine and Reports</li>
                      <li>Eliminate Fraud</li>
                    </ul>
                  </div>
                  <div class="col-sm-6 col-lg-4">
                    <ul class="custom-list">
                      <li>
                        Upload Records
                        <ol>
                          <li>Certified Records</li>
                          <li>Data Storage Locally or Cloud</li>
                        </ol>
                      </li>
                      <li>Privacy and Security</li>
                      <li>Parent/Child QR Code</li>
                      <li>Get-Together</li>
                      <li>Events</li>
                      <li>Airline Integration</li>
                    </ul>
                  </div>
                  <div class="col-sm-6 col-lg-4">
                    <ul class="custom-list">
                      <li>
                        Vendors
                        <ol>
                          <li>Scan QR Code</li>
                        </ol>
                      </li>
                      <li>
                        Labs/Clinics
                        <ol>
                          <li>Upload Records</li>
                        </ol>
                      </li>
                      <li>
                        Admin
                        <ol>
                          <li>Reports and Financials</li>
                        </ol>
                      </li>
                      <li>White Label for Countries and Companies</li>
                      <li>Local Partners</li>
                    </ul>
                  </div>
                </div>
                <div class="section-cta text-center mt-5">
                  <a
                    href="#lets_talk"
                    roal="button"
                    class="btn btn-orange scrollto"
                    title="Request a Demo"
                    >Request a Demo<i class="ms-2 fas fa-arrow-right"></i
                  ></a>
                </div>
              </div>
            </section>
            <!-- ! Section end -->
            <!-- ! Section start -->
            <section class="section curved-section">
              <div class="section-shape section-shape-top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1920"
                  height="84.752"
                  viewBox="0 0 1920 84.752"
                  class="shape-1"
                >
                  <g transform="translate(0 -0.248)">
                    <g transform="translate(0 -402)">
                      <path
                        d="M0,439.134q262.562,38.639,458.711,38.638c196.15,0,411.934-35.14,747.269-67.579q335.34-32.44,714.02,67.579V487H0Z"
                        fill-rule="evenodd"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="container section-container-padding">
                <div class="section-top-bar d-flex">
                  <div class="section-top-bar-container">
                    <h2 class="section-title">
                      VAXTRAX <span>SAFEGUARDS?</span>
                    </h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-lg-3 mb-5 mb-lg-0">
                    <div class="white-box">
                      <div class="image-box text-center">
                        <img src="@/assets/images/symptoms.png" />
                      </div>
                      <div class="box-content">
                        <div class="mb-4">
                          <h4>Symptoms</h4>
                        </div>
                        <ul class="custom-list">
                          <li>Fever</li>
                          <li>Dry cough</li>
                          <li>Shortness of breath</li>
                          <li>Fatigue</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-lg-3 mb-5 mb-lg-0">
                    <div class="white-box">
                      <div class="image-box text-center">
                        <img src="@/assets/images/transmission.png" />
                      </div>
                      <div class="box-content">
                        <div class="mb-4">
                          <h4>Transmission</h4>
                        </div>
                        <ul class="custom-list">
                          <li>Air droplets</li>
                          <li>Poor ventilation</li>
                          <li>Human contact</li>
                          <li>Contaminated surfaces</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-lg-3">
                    <div class="white-box">
                      <div class="image-box text-center">
                        <img src="@/assets/images/prevention.png" />
                      </div>
                      <div class="box-content">
                        <div class="mb-4">
                          <h4>Prevention</h4>
                        </div>
                        <ul class="custom-list">
                          <li>Use mask</li>
                          <li>Wash hands</li>
                          <li>Avoid contact with animals</li>
                          <li>Avoid crowded places</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-lg-3">
                    <div class="white-box">
                      <div class="image-box text-center">
                        <img src="@/assets/images/vaccination.png" />
                      </div>
                      <div class="box-content">
                        <div class="mb-4">
                          <h4>Protection</h4>
                        </div>
                        <ul class="custom-list">
                          <li>COVID vaccine dose 1 & 2</li>
                          <li>COVID booster vaccine</li>
                          <li>COVID testing as necessary</li>
                          <li>Annual flu & health vaccines</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="section-cta text-center mt-5">
                  <a
                    class="btn btn-orange mr-5"
                    data-bs-toggle="modal"
                    href="#exampleModalToggle"
                    role="button"
                    title="Covid-19 Variants"
                    >Covid-19 Variants <i class="ms-2 fas fa-arrow-right"></i
                  ></a>

                  <a
                    class="btn "
                    data-bs-toggle="modal"
                    href="#Pills"
                    role="button"
                    title="Covid-19 Variants"
                    >Covid-19 Approved Pills
                    <i class="ms-2 fas fa-arrow-right"></i
                  ></a>
                </div>

                <!-- Full screen modal -->
                <div
                  class="modal fade"
                  id="exampleModalToggle"
                  aria-hidden="true"
                  aria-labelledby="exampleModalToggleLabel"
                  tabindex="-1"
                >
                  <div class="modal-dialog modal-xl modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalToggleLabel">
                          Covid-19 Variants
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <img src="../assets/images/corona-variants.png" />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Full screen modal -->
                <div
                  class="modal fade"
                  id="Pills"
                  aria-hidden="true"
                  aria-labelledby="exampleModalToggleLabel"
                  tabindex="-1"
                >
                  <div class="modal-dialog modal-xl modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="Pills">
                          Covid-19 Pills
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <img src="../assets/images/covid-pills.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- ! Section end -->
            <!-- ! Section start -->
            <section class="section curved-section">
              <div class="section-shape section-shape-top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1920"
                  height="84.752"
                  viewBox="0 0 1920 84.752"
                  class="shape-1"
                >
                  <g transform="translate(0 -0.248)">
                    <g transform="translate(0 -402)">
                      <path
                        d="M0,439.134q262.562,38.639,458.711,38.638c196.15,0,411.934-35.14,747.269-67.579q335.34-32.44,714.02,67.579V487H0Z"
                        fill-rule="evenodd"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="container section-container-padding">
                <div class="section-top-bar d-flex">
                  <div class="section-top-bar-container">
                    <h2 class="section-title">
                      Records <span>at a Glance</span>
                    </h2>
                  </div>
                </div>
                <div class="odd-even-col">
                  <div class="row g-0 odd-even-block">
                    <div class="col-lg-6 text-center">
                      <img src="@/assets/images/vtg-qr-code.png" />
                    </div>
                    <div class="odd-even-right-col col-lg-6">
                      <div
                        class="
                          odd-even-content-main
                          d-flex
                          align-items-center
                          h-100
                          flex-wrap
                        "
                      >
                        <div class="odd-even-content">
                          <h3 class="mb-3">Unique QR Code</h3>
                          <p>
                            Once create a profile, the system will generate a
                            unique QR Code for retrieving your records.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row g-0 odd-even-block">
                    <div class="col-lg-6 text-center">
                      <img src="@/assets/images/degital-card.png" />
                    </div>
                    <div class="odd-even-right-col col-lg-6">
                      <div
                        class="
                          odd-even-content-main
                          d-flex
                          align-items-center
                          h-100
                          flex-wrap
                        "
                      >
                        <div class="odd-even-content">
                          <h3 class="mb-3">Digital ID</h3>
                          <p>
                            A digital card will be generated from your profile
                            details submitted and will be displayed in the main
                            menu.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row g-0 odd-even-block">
                    <div class="col-lg-6 text-center">
                      <img src="@/assets/images/vaccination-card.png" />
                    </div>
                    <div class="odd-even-right-col col-lg-6">
                      <div
                        class="
                          odd-even-content-main
                          d-flex
                          align-items-center
                          h-100
                          flex-wrap
                        "
                      >
                        <div class="odd-even-content">
                          <h3 class="mb-3">ID Card</h3>
                          <p>
                            Users can purchase a plastic card once approved by
                            the countries or events as a backup for lost or
                            misplaced mobile devices to access your details.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row g-0 odd-even-block">
                    <div class="col-lg-6 text-center">
                      <img src="@/assets/images/visa-stickers.png" />
                    </div>
                    <div class="odd-even-right-col col-lg-6">
                      <div
                        class="
                          odd-even-content-main
                          d-flex
                          align-items-center
                          h-100
                          flex-wrap
                        "
                      >
                        <div class="odd-even-content">
                          <h3 class="mb-3">Visa Stickers</h3>
                          <p>
                            A passport visa sticker can be purchased once
                            approved by the countries for attaching in your
                            passport when traveling for presenting to
                            immigration and other places.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- ! Section end -->
            <!-- ! Section start -->
            <section class="section curved-section">
              <div class="section-shape section-shape-top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1920"
                  height="84.752"
                  viewBox="0 0 1920 84.752"
                  class="shape-1"
                >
                  <g transform="translate(0 -0.248)">
                    <g transform="translate(0 -402)">
                      <path
                        d="M0,439.134q262.562,38.639,458.711,38.638c196.15,0,411.934-35.14,747.269-67.579q335.34-32.44,714.02,67.579V487H0Z"
                        fill-rule="evenodd"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="container section-container-padding">
                <div class="section-top-bar d-flex">
                  <div class="section-top-bar-container">
                    <h2 class="section-title">VTG <span>PROFILES</span></h2>
                  </div>
                </div>
                <div class="odd-even-col">
                  <div class="row g-0 odd-even-block">
                    <div class="col-lg-6">
                      <div class="odd-even-left-col">
                        <div
                          class="background-holder"
                          id="background-holderImg1"
                        ></div>
                      </div>
                    </div>
                    <div class="odd-even-right-col col-lg-6">
                      <div
                        class="
                          odd-even-content-main
                          d-flex
                          align-items-center
                          h-100
                          flex-wrap
                        "
                      >
                        <div class="odd-even-content">
                          <h3 class="mb-3">Users</h3>
                          <ul class="custom-list">
                            <li>Unique QR Code</li>
                            <li>Digital card</li>
                            <li>Group gathering</li>
                            <li>Notification user autherization</li>
                            <li>Upload test and vaccine</li>
                            <li>Parent/Child - Single QR Code</li>
                            <li>
                              Optional Purchase:
                              <ol>
                                <li>Card</li>
                                <li>Visa sticker</li>
                                <li>Certify test and vaccines</li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row g-0 odd-even-block">
                    <div class="col-lg-6">
                      <div class="odd-even-left-col">
                        <div
                          class="background-holder"
                          id="background-holderImg2"
                        ></div>
                      </div>
                    </div>
                    <div class="odd-even-right-col col-lg-6">
                      <div
                        class="
                          odd-even-content-main
                          d-flex
                          align-items-center
                          h-100
                          flex-wrap
                        "
                      >
                        <div class="odd-even-content">
                          <h3 class="mb-3">Vendors</h3>
                          <ul class="custom-list">
                            <li>Profile with company logo</li>
                            <li>Verifications</li>
                            <li>Scans QR Code</li>
                            <li>One QR Code for multiple locations</li>
                            <li>
                              Employee detached from system once leave IP
                              address
                            </li>
                            <li>Reports and transactions</li>
                            <li>Countries and Airports economic benefits</li>
                            <li>Fraud and Accountability</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row g-0 odd-even-block">
                    <div class="col-lg-6">
                      <div class="odd-even-left-col">
                        <div
                          class="background-holder"
                          id="background-holderImg3"
                        ></div>
                      </div>
                    </div>
                    <div class="odd-even-right-col col-lg-6">
                      <div
                        class="
                          odd-even-content-main
                          d-flex
                          align-items-center
                          h-100
                          flex-wrap
                        "
                      >
                        <div class="odd-even-content">
                          <h3 class="mb-3">Lab/Clinic</h3>
                          <ul class="custom-list">
                            <li>Profile with company logo</li>
                            <li>Verifications</li>
                            <li>Upload test and vaccines</li>
                            <li>Instant certified test and vaccines</li>
                            <li>One QR Code for multiple locations</li>
                            <li>
                              Employee detached from system once leave IP
                              address
                            </li>
                            <li>Reports and transactions</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row g-0 odd-even-block">
                    <div class="col-lg-6">
                      <div class="odd-even-left-col">
                        <div
                          class="background-holder"
                          id="background-holderImg4"
                        ></div>
                      </div>
                    </div>
                    <div class="odd-even-right-col col-lg-6">
                      <div
                        class="
                          odd-even-content-main
                          d-flex
                          align-items-center
                          h-100
                          flex-wrap
                        "
                      >
                        <div class="odd-even-content">
                          <h3 class="mb-3">Admin</h3>
                          <ul class="custom-list">
                            <li>Upload employees data</li>
                            <li>Scans</li>
                            <li>Setup IP locations</li>
                            <li>Invoices</li>
                            <li>Reports and transactions</li>
                            <li>Tracing and quarantine</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- ! Section end -->
            <!-- ! Section start -->
            <section class="section curved-section">
              <div class="section-shape section-shape-top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1920"
                  height="84.752"
                  viewBox="0 0 1920 84.752"
                  class="shape-1"
                >
                  <g transform="translate(0 -0.248)">
                    <g transform="translate(0 -402)">
                      <path
                        d="M0,439.134q262.562,38.639,458.711,38.638c196.15,0,411.934-35.14,747.269-67.579q335.34-32.44,714.02,67.579V487H0Z"
                        fill-rule="evenodd"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="container section-container-padding">
                <div class="section-top-bar d-flex">
                  <div class="section-top-bar-container">
                    <h2 class="section-title">Key <span>Features</span></h2>
                  </div>
                </div>
                <div class="row justify-content-center mb-n3">
                  <div class="col-lg-4 col-md-6 col-12 mb-5">
                    <!-- Start Single Feature -->
                    <div class="single-feature h-100">
                      <i class="icon"
                        ><img src="../assets/images/icons/parent-icon.png"
                      /></i>
                      <h4>Parent/Child</h4>
                      <p>
                        Where parents can have their younger children (under 13)
                        or older parents under their profile
                      </p>
                    </div>
                    <!-- End Single Feature -->
                  </div>
                  <div class="col-lg-4 col-md-6 col-12 mb-5">
                    <!-- Start Single Feature -->
                    <div class="single-feature h-100">
                      <i class="icon"
                        ><img src="../assets/images/icons/no-racism.png"
                      /></i>
                      <h4>Get-Together</h4>
                      <p>
                        Users can create groups and invite friends and families
                        for getting together and view the attendees status
                        (weddings, parties, social)
                      </p>
                    </div>
                    <!-- End Single Feature -->
                  </div>
                  <div class="col-lg-4 col-md-6 col-12 mb-5">
                    <!-- Start Single Feature -->
                    <div class="single-feature h-100">
                      <i class="icon"
                        ><img src="@/assets/images/icons/conference.png"
                      /></i>
                      <h4>Events</h4>
                      <p>
                        The VTG app can be used for large events and tradeshows.
                      </p>
                    </div>
                    <!-- End Single Feature -->
                  </div>
                  <div class="col-lg-4 col-md-6 col-12 mb-5">
                    <!-- Start Single Feature -->
                    <div class="single-feature h-100">
                      <i class="icon"
                        ><img src="@/assets/images/icons/airline.png"
                      /></i>
                      <h4>Airlines</h4>
                      <p>
                        Integration with Airline schedules for notifications of
                        requirements of test or vaccines
                      </p>
                    </div>
                    <!-- End Single Feature -->
                  </div>
                  <div class="col-lg-4 col-md-6 col-12 mb-5">
                    <!-- Start Single Feature -->
                    <div class="single-feature h-100">
                      <i class="icon"
                        ><img src="@/assets/images/icons/notification.png"
                      /></i>
                      <h4>Notifications</h4>
                      <p>
                        User will be notified when their QR codes are scanned to
                        view their status and can accept or block vendors.
                      </p>
                    </div>
                    <!-- End Single Feature -->
                  </div>
                  <div class="col-lg-4 col-md-6 col-12 mb-5">
                    <!-- Start Single Feature -->
                    <div class="single-feature h-100">
                      <i class="icon"
                        ><img src="@/assets/images/icons/quarantine.png"
                      /></i>
                      <h4>Quarantine & Tracing</h4>
                      <p>
                        If a User is tested positive for the virus VAXTRAX can
                        manage the quarantine process, tracing & movement and
                        retesting for achieving a negative result.
                      </p>
                    </div>
                    <!-- End Single Feature -->
                  </div>
                </div>
              </div>
              <section class="section curved-section" id="process">
                <div class=" section-shape section-shape-top">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1920"
                    height="84.752"
                    viewBox="0 0 1920 84.752"
                    class="shape-1"
                  >
                    <g transform="translate(0 -0.248)">
                      <g transform="translate(0 -402)">
                        <path
                          d="M0,439.134q262.562,38.639,458.711,38.638c196.15,0,411.934-35.14,747.269-67.579q335.34-32.44,714.02,67.579V487H0Z"
                          fill-rule="evenodd"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="container section-container-padding">
                  <div class="section-top-bar d-flex">
                    <div class="section-top-bar-container">
                      <h2 class="section-title">
                        Our Work <span>Process</span>
                      </h2>
                    </div>
                  </div>
                  <div class="mb-5">
                    <ul class="process-wrapp">
                      <li class="process-flow">
                        <span class="pro-step mb-3">01</span>
                        <div class="process-text">
                          <h6>Registration</h6>
                          <p class="mt-n2 mt-sm-0">
                            Phone No, Email and Password
                          </p>
                        </div>
                      </li>
                      <li class="process-wrapp">
                        <span class="pro-step mb-3">02</span>
                        <div class="process-text">
                          <h6>Profile</h6>
                          <p class="mt-n2 mt-sm-0">
                            Names, Gender, DOB, ID and Citizenship
                          </p>
                        </div>
                      </li>
                      <li class="process-wrapp">
                        <span class="pro-step mb-3">03</span>
                        <div class="process-text">
                          <h6>QR Code</h6>
                          <p class="mt-n2 mt-sm-0">Unique code for status</p>
                        </div>
                      </li>
                      <li class="process-wrapp">
                        <span class="pro-step mb-3">04</span>
                        <div class="process-text">
                          <h6>Vaccines/Tests</h6>
                          <p class="mt-n2 mt-sm-0">
                            Upload, history and certificates
                          </p>
                        </div>
                      </li>
                      <li class="process-wrapp">
                        <span class="pro-step mb-3">05</span>
                        <div class="process-text">
                          <h6>Health Info</h6>
                          <p class="mt-n2 mt-sm-0">
                            Other vaccines and tests records
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="row d-flex align-items-center" id="app-feature">
                    <div class="col-lg-4 col-md-4 col-sm-6 order-1 order-md-0">
                      <div class="text-center text-md-end">
                        <div class="feature-item mt-5">
                          <div class="icon">
                            <i class="fas fa-sign-in-alt"></i>
                          </div>
                          <div class="text ms-md-auto">
                            <h4>
                              <span class="d-inline-block">Login</span>
                            </h4>
                            <p>Phone No or Email, Password for QR code</p>
                          </div>
                        </div>
                        <div class="feature-item mt-5 wow fadeInLeft">
                          <div class="icon"><i class="fas fa-users"></i></div>
                          <div class="text ms-md-auto">
                            <h4>
                              <span class="d-inline-block">Vendor</span>
                            </h4>
                            <p>Show QR code for scanning and display status</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 text-center order-md-2">
                      <div class="app-image top30">
                        <div class="app-slider-lock-btn"></div>
                        <div class="app-slider-lock">
                          <!-- <img
                            src="../assets/img/app-images/iphone-slide-lock.jpg"
                            alt="app-logo"
                          /> -->
                          <img v-show="image" :key="image.id" @click="switchImage" class="image" :src="image.src" alt="image.alt">
                        </div>
                        <!-- <div id="app-slider" class="owl-carousel">
                          <div class="item">
                            <img
                              src="../assets/img/app-images/iphone-slide1.jpg"
                              alt="App slide 1"
                            />
                          </div>
                          <div class="item">
                            <img
                              src="../assets/img/app-images/iphone-slide2.jpg"
                              alt="App slide 2"
                            />
                          </div>
                          <div class="item">
                            <img
                              src="../assets/img/app-images/iphone-slide3.jpg"
                              alt="App slide 3"
                            />
                          </div>
                          <div class="item">
                            <img
                              src="../assets/img/app-images/iphone-slide4.jpg"
                              alt="App slide 4"
                            />
                          </div>
                          <div class="item">
                            <img
                              src="../assets/img/app-images/iphone-slide5.jpg"
                              alt="App slide 5"
                            />
                          </div>
                          <div class="item">
                            <img
                              src="../assets/img/app-images/iphone-slide6.jpg"
                              alt="App slide 6"
                            />
                          </div>
                          <div class="item">
                            <img
                              src="../assets/img/app-images/iphone-slide7.jpg"
                              alt="App slide 7"
                            />
                          </div>
                          <div class="item">
                            <img
                              src="../assets/img/app-images/iphone-slide8.jpg"
                              alt="App slide 8"
                            />
                          </div>
                          <div class="item">
                            <img
                              src="../assets/img/app-images/iphone-slide9.jpg"
                              alt="App slide 9"
                            />
                          </div>
                        </div> -->
                        <img
                          src="../assets/img/app-images/iphone.png"
                          alt="Mobile image"
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 order-2 order-md-3">
                      <div class="text-center text-md-start">
                        <div class="feature-item mt-5">
                          <div class="icon">
                            <i class="fas fa-hospital-user"></i>
                          </div>
                          <div class="text">
                            <h4>
                              <span class="d-inline-block">Lab/Clinic</span>
                            </h4>
                            <p>
                              Show QR code or phone no for Uploding vaccines and
                              test
                            </p>
                          </div>
                        </div>
                        <div class="feature-item mt-5 wow fadeInRight">
                          <div class="icon">
                            <i class="fas fa-notes-medical"></i>
                          </div>
                          <div class="text">
                            <h4>
                              <span class="d-inline-block">Status</span>
                            </h4>
                            <p>
                              Displays: Green
                              <i class="bi bi-check-lg text-success"></i>,
                              Yellow
                              <i
                                class="fas fa-exclamation-triangle text-warning"
                              ></i>
                              or Red <i class="bi bi-x-lg text-danger"></i>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>
            <!-- ! Section end -->
            <!-- ! Section start -->
            <section class="section curved-section">
              <div class="section-shape section-shape-top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1920"
                  height="84.752"
                  viewBox="0 0 1920 84.752"
                  class="shape-1"
                >
                  <g transform="translate(0 -0.248)">
                    <g transform="translate(0 -402)">
                      <path
                        d="M0,439.134q262.562,38.639,458.711,38.638c196.15,0,411.934-35.14,747.269-67.579q335.34-32.44,714.02,67.579V487H0Z"
                        fill-rule="evenodd"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="container section-container-padding">
                <div class="section-top-bar d-flex">
                  <div class="section-top-bar-container">
                    <h2 class="section-title">
                      Where <span>to use VTG platform</span>
                    </h2>
                  </div>
                </div>

                <div class="canUsedCarousel">
                  <div class="prevBtn" @click="showPrev">
                    <i class="fa fa-chevron-left"></i>
                  </div>
                  <div class="nextBtn" @click="showNext">
                    <i class="fa fa-chevron-right"></i>
                  </div>
                  <carousel
                    arrows="false"
                    autoplay="false"
                    class="shadow-lg"
                    ref="carousel"
                    items="1"
                    nav="true"
                  >
                    <div class="slickSlide">
                      <img
                        draggable="false"
                        src="@/assets/images/keyfeture-image/stadium.png"
                        alt="Trulli"
                      />

                      <!-- <div class="can-be-used-slide-caption">
                          <h6>Stadium</h6>
                          <p>
                            User will be notified when their QR codes are
                            scanned to view their status and can accept or block
                            vendors.
                          </p>
                        </div> -->
                    </div>

                    <div>
                      <img
                        draggable="false"
                        src="@/assets/images/keyfeture-image/immigration.png"
                        alt="Trulli"
                        style="width: 100%; border-radius:15px;"
                        class="carouselImg"
                      />

                      <div class="can-be-used-slide-caption container">
                        <h6>Immigration</h6>
                        <p>
                          User will be notified when their QR codes are scanned
                          to view their status and can accept or block vendors.
                        </p>
                      </div>
                    </div>

                    <div>
                      <img
                        draggable="false"
                        src="@/assets/images/keyfeture-image/hotels.png"
                        alt="Trulli"
                        style="width: 100%"
                      />

                      <div class="can-be-used-slide-caption container">
                        <h6>Hotels</h6>
                        <p>
                          User will be notified when their QR codes are scanned
                          to view their status and can accept or block vendors.
                        </p>
                      </div>
                    </div>

                    <div>
                      <img
                        draggable="false"
                        src="@/assets/images/keyfeture-image/hospitals.png"
                        alt="Trulli"
                        style="width: 100%"
                      />

                      <div class="can-be-used-slide-caption container">
                        <h6>Hospitals, Labs, Clinics</h6>
                        <p>
                          User will be notified when their QR codes are scanned
                          to view their status and can accept or block vendors.
                        </p>
                      </div>
                    </div>

                    <div>
                      <img
                        draggable="false"
                        src="@/assets/images/keyfeture-image/stores.png"
                        alt="Trulli"
                        style="width: 100%"
                      />

                      <div class="can-be-used-slide-caption container">
                        <h6>stores</h6>
                        <p>
                          User will be notified when their QR codes are scanned
                          to view their status and can accept or block vendors.
                        </p>
                      </div>
                    </div>

                    <div>
                      <img
                        draggable="false"
                        src="@/assets/images/keyfeture-image/airport.png"
                        alt="Trulli"
                        style="width: 100%"
                      />

                      <div class="can-be-used-slide-caption container">
                        <h6>Airport</h6>
                        <p>
                          Integration with Airline schedules for notifications
                          of requirements of test or vaccines
                        </p>
                      </div>
                    </div>

                    <div>
                      <img
                        draggable="false"
                        src="@/assets/images/keyfeture-image/restaurant.png"
                        alt="Trulli"
                        style="width: 100%"
                      />

                      <div class="can-be-used-slide-caption container">
                        <h6>Restaurant</h6>
                        <p>
                          Where parents can have their younger children (under
                          13) or older parents under their profile
                        </p>
                      </div>
                    </div>

                    <div>
                      <img
                        draggable="false"
                        src="@/assets/images/keyfeture-image/concert.png"
                        alt="Trulli"
                        style="width: 100%"
                      />

                      <div class="can-be-used-slide-caption container">
                        <h6>Concert</h6>
                        <p>
                          Users can create groups and invite friends and
                          families for getting together and view the attendees
                          status (weddings, parties, social)
                        </p>
                      </div>
                    </div>
                  </carousel>
                </div>
              </div>
            </section>
            <!-- ! Section end -->
            <!-- ! Section start -->
            <section id="vtg_app" class="section curved-section">
              <div class="container section-container-padding">
                <div class="section-top-bar d-flex">
                  <div class="section-top-bar-container">
                    <h2 class="section-title">The VTG <span>App</span></h2>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-sm-6 text-center mb-4 mb-sm-0">
                    <img
                      src="@/assets/images/app-image.png"
                      class="img-fluid"
                      alt="VTG App"
                    />
                  </div>
                  <div class="col-sm-6">
                    <h3 class="mb-3">Download Now</h3>
                    <p>
                      The VTG app have multiple options of presenting your COVID
                      test and vaccines by QR Code, ID Card or Passport sticker.
                      Also, a card can be issued for events with color codes for
                      the admittance and access to different programs.
                    </p>
                    <div class="download-links mt-5">
                      <ul>
                        <li>
                          <a href="../assets/app/vax-trax.apk" download="">
                            <img src="@/assets/images/android-icon.png" />
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <img src="@/assets/images/apple-icon.png" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- ! Section end -->

            <!-- ! Section start -->
            <section class="section curved-section">
              <div class="section-shape section-shape-top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1920"
                  height="84.752"
                  viewBox="0 0 1920 84.752"
                  class="shape-1"
                >
                  <g transform="translate(0 -0.248)">
                    <g transform="translate(0 -402)">
                      <path
                        d="M0,439.134q262.562,38.639,458.711,38.638c196.15,0,411.934-35.14,747.269-67.579q335.34-32.44,714.02,67.579V487H0Z"
                        fill-rule="evenodd"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="container section-container-padding">
                <div class="section-top-bar d-flex">
                  <div class="section-top-bar-container">
                    <h2 class="section-title">
                      COVID-19 <span>Dashboard</span>
                    </h2>
                  </div>
                </div>
                <div
                  class="frame_statistic"
                  data-mobile-frame="https://www.arcgis.com/apps/opsdashboard/index.html#/85320e2ea5424dfaaa75ae62e5c06e61"
                >
                  <iframe
                    src="https://www.arcgis.com/apps/opsdashboard/index.html#/bda7594740fd40299423467b48e9ecf6"
                    allowfullscreen=""
                  ></iframe>
                </div>
              </div>
            </section>
            <!-- ! Section end -->
            <!-- ! Section start -->
            <section class="section curved-section">
              <div class="section-shape section-shape-top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1920"
                  height="84.752"
                  viewBox="0 0 1920 84.752"
                  class="shape-1"
                >
                  <g transform="translate(0 -0.248)">
                    <g transform="translate(0 -402)">
                      <path
                        d="M0,439.134q262.562,38.639,458.711,38.638c196.15,0,411.934-35.14,747.269-67.579q335.34-32.44,714.02,67.579V487H0Z"
                        fill-rule="evenodd"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="container section-container-padding">
                <div class="section-top-bar d-flex">
                  <div class="section-top-bar-container">
                    <h2 class="section-title">
                      Leading COVID-19 <span>Vaccines</span>
                    </h2>
                  </div>
                </div>
                <div class="table-responsive">
                  <table
                    role="table"
                    class="
                      table
                      b-table
                      text-center
                      table-striped table-hover 
                      align-middle
                    "
                  >
                    <thead role="rowgroup" class="table-dark">
                      <tr role="row">
                        <th role="columnheader" scope="col">
                          <div>Developer</div>
                        </th>
                        <th role="columnheader" scope="col">
                          <div>Country</div>
                        </th>
                        <th role="columnheader" scope="col">
                          <div>Clinical Phase</div>
                        </th>
                        <th role="columnheader" scope="col">
                          <div>Efficacy</div>
                        </th>
                        <th role="columnheader" scope="col">
                          <div>Doses</div>
                        </th>
                        <th role="columnheader" scope="col">
                          <div>Approve In At Lease One Country</div>
                        </th>
                        <th role="columnheader" scope="col">
                          <div>Approve In United States</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup">
                      <tr role="row">
                        <td role="cell">CanSino</td>
                        <td role="cell">China</td>
                        <td role="cell">3</td>
                        <td role="cell">66%</td>
                        <td role="cell">1</td>
                        <td role="cell">Yes</td>
                        <td role="cell"></td>
                      </tr>
                      <tr role="row">
                        <td role="cell">Sinopharm (Beijing)</td>
                        <td role="cell">China</td>
                        <td role="cell">3</td>
                        <td role="cell">78%</td>
                        <td role="cell">1</td>
                        <td role="cell">Yes</td>
                        <td role="cell"></td>
                      </tr>
                      <tr role="row">
                        <td role="cell">Sinopharm (Wuhan)</td>
                        <td role="cell">China</td>
                        <td role="cell">3</td>
                        <td role="cell">51%-84%</td>
                        <td role="cell">1</td>
                        <td role="cell">Yes</td>
                        <td role="cell"></td>
                      </tr>
                      <tr role="row">
                        <td role="cell">Sinovac</td>
                        <td role="cell">China</td>
                        <td role="cell">3</td>
                        <td role="cell">78%</td>
                        <td role="cell">1</td>
                        <td role="cell">Yes</td>
                        <td role="cell"></td>
                      </tr>
                      <tr role="row">
                        <td role="cell">Bharat</td>
                        <td role="cell">India</td>
                        <td role="cell">3</td>
                        <td role="cell">78%</td>
                        <td role="cell">2</td>
                        <td role="cell">Yes</td>
                        <td role="cell"></td>
                      </tr>
                      <tr role="row">
                        <td role="cell">Gamaleya</td>
                        <td role="cell">Russia</td>
                        <td role="cell">3</td>
                        <td role="cell">92%</td>
                        <td role="cell">2</td>
                        <td role="cell">Yes</td>
                        <td role="cell"></td>
                      </tr>
                      <tr role="row">
                        <td role="cell">Vector Institute</td>
                        <td role="cell">Russia</td>
                        <td role="cell">3</td>
                        <td role="cell"></td>
                        <td role="cell">2</td>
                        <td role="cell">Yes</td>
                        <td role="cell"></td>
                      </tr>
                      <tr role="row">
                        <td role="cell">Oxford-AstraZeneca</td>
                        <td role="cell">United Kingdom, Sweden</td>
                        <td role="cell">2 and 3</td>
                        <td role="cell">74%–100%</td>
                        <td role="cell">2</td>
                        <td role="cell">Yes</td>
                        <td role="cell"></td>
                      </tr>
                      <tr role="row">
                        <td role="cell">Pfizer-BioNTech</td>
                        <td role="cell">United States, Germany</td>
                        <td role="cell">2 and 3</td>
                        <td role="cell">91%</td>
                        <td role="cell">2</td>
                        <td role="cell">Yes</td>
                        <td role="cell">Yes</td>
                      </tr>
                      <tr role="row">
                        <td role="cell">Johnson &amp; Johnson</td>
                        <td role="cell">United States</td>
                        <td role="cell">3</td>
                        <td role="cell">64%–⁠72%</td>
                        <td role="cell">1</td>
                        <td role="cell">Yes</td>
                        <td role="cell">Yes</td>
                      </tr>
                      <tr role="row">
                        <td role="cell">Moderna</td>
                        <td role="cell">United States</td>
                        <td role="cell">3</td>
                        <td role="cell">93%–98%</td>
                        <td role="cell">2</td>
                        <td role="cell">Yes</td>
                        <td role="cell">Yes</td>
                      </tr>
                      <tr role="row">
                        <td role="cell">Novavax</td>
                        <td role="cell">United States</td>
                        <td role="cell">3</td>
                        <td role="cell">90%</td>
                        <td role="cell">2</td>
                        <td role="cell"></td>
                        <td role="cell"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
            <!-- ! Section end -->
          </div>
          <!-- ! Section start -->
          <section
            id="lets_talk"
            class="section curved-section lets-talk-section cover-bg text-white"
          >
            <!--   <section class="section curved-section lets-talk-section cover-bg"> -->
            <div class="container section-container-padding">
              <div class="section-top-bar d-flex">
                <div class="section-top-bar-container">
                  <h2 class="section-title title-white">
                    Let's <span>talk</span>
                  </h2>
                </div>
              </div>
              <div class="contact-box">
                <form
                  action="../assets/forms/contact.php"
                  method="post"
                  role="form"
                  class="php-email-form h-100"
                >
                  <div class="row">
                    <div class="col-md-6 form-group mb-4">
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        id="name"
                        placeholder="Your Name"
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                      />
                      <div class="validate"></div>
                    </div>
                    <div class="col-md-6 form-group mb-4">
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        data-rule="email"
                        data-msg="Please enter a valid email"
                      />
                      <div class="validate"></div>
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <input
                      type="text"
                      class="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 8 chars of subject"
                    />
                    <div class="validate"></div>
                  </div>
                  <div class="form-group mb-4">
                    <textarea
                      class="form-control"
                      name="message"
                      rows="5"
                      data-rule="required"
                      data-msg="Please write something for us"
                      placeholder="Message"
                    ></textarea>
                    <div class="validate"></div>
                  </div>
                  <div class="mb-3">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div class="text-center">
                    <button class="btn btn-orange" type="submit">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
          <!-- ! Section end -->

          <!-- ! Main end -->
        </main>
        <!-- ! Main end -->
        <div class="push"></div>
      </div>
      <!-- ! Wrapper End -->
      <!-- ======= Footer ======= -->
      <Footer />
    </div>
    <div id="cookie-bar" v-if="showToaster">
      <div id="contentcookie">
        <p>
          We use cookies to improve user experience and analyze website traffic.
          By clicking "Accept", you agree to our website's cookies use as
          described in our <a href="#/cookies-policy">Cookie Policy</a>. You can
          change your cookies settings at any time by clicking "Prefrences"
        </p>
        <button id="Cookie" tabindex="1" @click="AcceptCookies()">
          Accept
        </button>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import $ from "jquery";
import carousel from "vue-owl-carousel";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "@/assets/js/bootstrap.min.js";
import "@/assets/js/wow.js";
import "@/assets/js/venobox.min.js";
import "@/assets/js/validate.js";
import "@/assets/js/global.js";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
export default {
  name: "Home",
  components: {
    Navbar,
    VueSlickCarousel,
    Footer,
    carousel,
  },
  data() {
    return {
      showToaster: false,
      index: 0,
      image: null,
      images: [
        {
          id: 1 ,
          src: require("../assets/img/app-images/iphone-slide-lock.jpg"),
        },
        {
          id: 2,
          src: require("../assets/img/app-images/iphone-slide2.jpg"),
        },
        {
          id: 3,
          src: require("../assets/img/app-images/iphone-slide3.jpg"),
        },
        {
          id: 4,
          src: require("../assets/img/app-images/iphone-slide4.jpg"),
        },
        {
          id: 5,
          src: require("../assets/img/app-images/iphone-slide4.jpg"),
        },
        {
          id: 6,
          src: require("../assets/img/app-images/iphone-slide6.jpg"),
        },
        {
          id: 7,
          src: require("../assets/img/app-images/iphone-slide7.jpg"),
        },
        {
          id: 8,
          src: require("../assets/img/app-images/iphone-slide8.jpg"),
        },
        {
          id: 9,
          src: require("../assets/img/app-images/iphone-slide9.jpg"),
        },
      ],
    };
  },
  mounted() {
    console.log(process.env.VTG_API_URL)
    this.switchImage();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    var status = sessionStorage.getItem("SetCookies");
    console.log(status);
    if (status) {
      this.showToaster = false;
    } else {
      this.showToaster = true;
    }
    var loginData = sessionStorage.getItem("LoginData");

    window.addEventListener("load", () => {
      setTimeout(() => {
        this.removeLoader();
      }, 1000);
    });

    setTimeout(() => {
      this.removeLoader();
    }, 4);

    $(".venobox").venobox();
  },
  methods: {
    switchImage() {
      this.image = this.images[this.index];
      this.index = (this.index + 1) % this.images.length;
    },
    removeLoader() {
      $("#loadingDiv").fadeOut(200, function() {
        // fadeOut complete. Remove the loading div
      });
    },
    AcceptCookies() {
      sessionStorage.setItem("SetCookies", true);

      $("#cookie-bar").fadeOut(200, function() {});
    },
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
  },
};
</script>
<style scoped>
@import "../assets/css/venobox.min.css";
@import "../assets/css/bootstrap.min.css";
@import "../assets/css/bootstrap-icons.css";
@import "../assets/css/screen.css";
@import "../assets/css/font-awesome.css";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap'); */
.home {
  font-family: "Raleway";
  /* font-weight: 500; */
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
td {
  border: 1px solid rgb(218, 213, 213);
}
.image {
  width: 100%;
  height: 100%;
  margin: 2px;
  cursor: pointer;
  transition: filter 0.3s ease-in;
}

.image:hover {
  filter: brightness(1.2);
}
.single-feature {
  padding: 42px;
}
.slickSlide {
  border-radius: 15px;
}
.carouselImg {
  border-radius: 15px;
}
.no-js #loader {
  display: none;
}
.js #loader {
  display: block;
  position: absolute;
  left: 100px;
  top: 0;
}
.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #fff;
}
.se-pre-con > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#cookie-bar {
  font-family: "Montserrat", sans-serif;
  background: #232c41;
  display: block;
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0px;
  color: #fff;
  width: 100%;
  z-index: 10;
}
#cookie-bar p {
  width: 85%;
  float: left;
  display: inline-block;
  font-size: 15px;
  line-height: 24px;
  color: #adadad;
}
#cookie-bar button {
  float: right;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 15px 20px;
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 5px;
  border-radius: 4px;
}
#cookie-bar a {
  color: #fff;
}
#contentcookie {
  padding: 20px 50px;
  width: 80%;
  margin: auto;
}

.clear {
  clear: both;
}

.btn {
  font-weight: 600;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 28px;
  border-radius: 50px;
  transition: ease-in-out 0.3s;
  color: #fff;
  background: #015eab;
  text-transform: uppercase;
  color: #fff;
  line-height: 15px;
}

.btn:hover,
.btn:focus {
  background: #eb9f3f;
  box-shadow: none;
  color: #fff;
}

.btn-orange {
  background: #eb9f3f;
}

.btn-orange:hover,
.btn-orange:focus {
  background: #015eab;
}

@media (max-width: 767px) {
  #cookie-bar p {
    width: 100%;
    font-size: 14px;
  }
  #cookie-bar button {
    width: 100%;
  }
  #cookie-bar a {
    color: #fff;
  }
  #contentcookie {
    padding: 20px 0px;
    width: 90%;
    margin: auto;
  }
}

.canUsedCarousel {
  position: relative;
}
.prevBtn,
.nextBtn {
  position: absolute;
  top: 40%;
  padding: 10px;
  background: #fff;
  z-index: 999;
  height: 45px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.prevBtn:hover,
.nextBtn:hover {
  background: #eb9f3f;
  color: #fff;
}
.prevBtn {
  left: 10px;
}
.nextBtn {
  right: 10px;
}
.can-be-used-slide-caption {
  bottom: 21px;
}
</style>
