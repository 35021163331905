<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  mounted() {
    AOS.init({
      offset: 200,
      duration: 500,
      easing: "ease-in-quad",
      delay: 10,
    });
  },
};
</script>

<style lang="scss">
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');

#app {
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: #2c3e50;
  overflow-x: hidden;
  overflow-y: hidden;
}
.back-button {
  background: rgb(134, 237, 255);
  color: rgb(7, 10, 10);
  font-size: 30px;
  line-height: 1;
  padding: 15px 20px 10px 20px;
  text-decoration: none;
  transition: all 0.2s;

}

.back-button:hover {
  background: rgba(78, 234, 255, 0.8);
  text-decoration: none;

}
</style>
