let jQuery = require('jquery');
let $ = require('jquery');
jQuery = jQuery.noConflict();
/* remove passive event Listener */
jQuery.event.special.touchstart = {
    setup: function(_, ns, handle) {
        if (ns.includes("noPreventDefault")) {
            this.addEventListener("touchstart", handle, { passive: false });
        } else {
            this.addEventListener("touchstart", handle, { passive: true });
        }
    }
};
// created var for footer Total Height.
var footerTotalHeight;
var windowWidth = document.body.clientWidth;

var VTG = {
    init: function() {

        // init code goes here
        //HTML5 Sementic elements
        document.createElement("header");
        document.createElement("nav");
        document.createElement("main");
        document.createElement("figure");
        document.createElement("footer");

        VTG.loadResize();
        jQuery('.venobox').venobox();

        jQuery(window).resize(function() {
            // VTG.loadResize function call on window resize
            VTG.loadResize();
        });

        VTG.menuSearchOpenClose();
        VTG.footerHeight();
        VTG.scrollSection();
        VTG.keyFeture();
        VTG.appSlider();
        // Push all external facing URLs to new window
        var links = jQuery("a[href^='http']");
        var domain = document.location.host;
        links.each(function(index, link) {
            if (!jQuery(link).attr("href").match(domain)) {
                jQuery(link).attr("target", "_blank");
            }
        });

    },

    loadResize: function() {
        VTG.windowWidth = document.body.clientWidth;
        VTG.footerHeight();

        // script for Desktop device
        if (VTG.windowWidth >= 768) {

        } else {

        }
    },

    /*
    Function Name : VTG.menuOpenClose
    Creates searchBtn behavior for search
    @version: 10-09-2019 updated
    */
    menuSearchOpenClose: function() {
        function headerScroll() {
            if (jQuery(this).scrollTop() > 50) {
                jQuery('.header').addClass('header-scroll');
            } else {
                jQuery('.header').removeClass('header-scroll');
            }
        };
        headerScroll();
        jQuery(window).scroll(function() {
            headerScroll();
        })
    },


    /*
    Function Name : footerHeight
    script to make for footer section always stick in page of the bottom.
    @version: 04-11-2019 updated
    */
    footerHeight: function() {

        // Define a variable for to get a footer height.
        var footerHeight = jQuery(".footer").outerHeight();
        // Define a variable for to get a footer elementor shape height.      
        var footerElementorHeight = jQuery(".footer .section-shape").outerHeight();
        // Define a variable for to get a footer height and elementor shape height.
        footerTotalHeight = (footerHeight - footerElementorHeight);
        // Define a variable for to find footer height and apply in push
        jQuery(".push").height(footerTotalHeight);
        // script for apply margin bottom in wrapper
        jQuery(".wrapper").css({
            "marginBottom": "-" + footerTotalHeight + "px"
        });
    },

    /*
    Function Name : scrollSection
    script to make for section scroll.
    @version: 04-11-2019 updated
    */
    scrollSection: function() {
        // Smooth scroll for the navigation menu and links with .scrollto classes

        var scrolltoOffset = jQuery('.header').outerHeight() - 1;
        jQuery(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function(e) {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = jQuery(this.hash);
                if (target.length) {
                    e.preventDefault();
                    var scrollto = target.offset().top;
                    var scrolled = 20;
                    if (jQuery('.header').length) {
                        scrollto -= jQuery('.header').outerHeight()
                        if (!jQuery('.header').hasClass('header-scrolled')) {
                            scrollto += scrolled;
                        }
                    }

                    if (jQuery(this).attr("href") == '.header') {
                        scrollto = 0;
                    }
                    jQuery('html, body').animate({
                        scrollTop: scrollto
                    }, 1500);
                    if (jQuery(this).parents('.nav-menu, .mobile-nav').length) {
                        jQuery('.nav-menu .active, .mobile-nav .active').removeClass('active');
                        jQuery(this).closest('li').addClass('active');
                    }
                    if (jQuery('body').hasClass('mobile-nav-active')) {
                        jQuery('body').removeClass('mobile-nav-active');
                        jQuery('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
                        jQuery('.mobile-nav-overly').fadeOut();
                    }

                    return false;
                }

            }

        });
        if (window.location.hash) {
            var initial_nav = window.location.hash;
            if (jQuery(initial_nav).length) {
                var scrollto = jQuery(initial_nav).offset().top - scrolltoOffset;
                jQuery('html, body').animate({
                    scrollTop: scrollto
                }, 1500);
            }
        }
        if (jQuery('.nav-menu').length) {

            var mobile_nav = jQuery('.nav-menu').clone().prop({

                class: 'mobile-nav d-xl-none'

            });

            jQuery('body').append(mobile_nav);

            jQuery('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="bi bi-list"></i>');

            jQuery('body').append('<div class="mobile-nav-overly"></div>');



            jQuery(document).on('click', '.mobile-nav-toggle', function(e) {

                jQuery('body').toggleClass('mobile-nav-active');

                jQuery('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');

                jQuery('.mobile-nav-overly').toggle();

            });



            jQuery(document).on('click', '.mobile-nav .drop-down > a', function(e) {

                e.preventDefault();

                jQuery(this).next().slideToggle(300);

                jQuery(this).parent().toggleClass('active');

            });
            jQuery(document).click(function(e) {

                var container = jQuery(".mobile-nav, .mobile-nav-toggle");

                if (!container.is(e.target) && container.has(e.target).length === 0) {

                    if (jQuery('body').hasClass('mobile-nav-active')) {

                        jQuery('body').removeClass('mobile-nav-active');

                        jQuery('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');

                        jQuery('.mobile-nav-overly').fadeOut();

                    }

                }

            });
            /*   var nav_sections = $('section');

              var main_nav = $('.nav-menu, .mobile-nav');



              $(window).on('scroll', function() {

                  var cur_pos = $(this).scrollTop() + 200;



                  nav_sections.each(function() {

                      var top = $(this).offset().top,

                          bottom = top + $(this).outerHeight();



                      if (cur_pos >= top && cur_pos <= bottom) {

                          if (cur_pos <= bottom) {

                              main_nav.find('li').removeClass('active');

                          }

                          main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');

                      }

                      if (cur_pos < 300) {

                          $(".nav-menu ul:first li:first").addClass('active');

                      }

                  });

              }); */

        } else if (jQuery(".mobile-nav, .mobile-nav-toggle").length) {
            jQuery(".mobile-nav, .mobile-nav-toggle").hide();
        }

    },
    keyFeture: function() {
        if (jQuery(".can-be-used-carousel").length) {
            jQuery('.can-be-used-carousel').owlCarousel({
                loop: true,
                margin: 0,
                autoplay: true,
                autoplayTimeout: 5000,
                nav: true,
                center: true,
                dots: false,
                mouseDrag: false,
                items: 1,
                navText: [
                    '<span><i class=\'bi bi-chevron-left\'></i></span>',
                    '<span><i class=\'bi bi-chevron-right\'></i></span>'
                ]
            });
        }

    },


    //App Slider
    appSlider: function() {
        jQuery("#app-slider").owlCarousel({
            items: 1,
            loop: false,
            dots: false,
            nav: false,
            autoWidth: true,
            // animateOut: 'fadeOut',
            // animateIn: 'fadeIn',
            autoplay: true,
            autoplayTimeout: 5000,
            // mouseDrag:false,
        });
        jQuery('.app-slider-lock-btn').on('click', function() {
            jQuery('.app-slider-lock').fadeToggle(600);
        });
    },

};

// when the page is ready, initialize everything
jQuery(document).on('ready', function() {
    VTG.init();
});