<template>
  <div class="header" id="header">
    <div class="container-fluid container-lg">
      <b-navbar toggleable="lg" class="p-0">
        <b-navbar-brand href="/"
          ><img
            src="../assets/images/logo-white.png"
            alt="Vax Trax Global Logo"
            class="navLogo ml-2 ml-lg-0"
            style=" transform: scale(1.1);"
        /></b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"
          ><template #default="{ expanded }">
            <img v-if="expanded" src="../assets/close.svg" />
            <img v-else src="../assets/hamburger.svg" /> </template
        ></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-navbar-nav class="align-items-center">
              <b-nav-item href="/" class="ml-2" id="home">Home</b-nav-item>
              <b-nav-item href="/about-us" class="ml-2" id="about"
                >About Us</b-nav-item
              >
              <b-nav-item href="/products" class="ml-2" id="products"
                >Products</b-nav-item
              >
              <!-- <b-nav-item href="/" class="ml-2">Pricing</b-nav-item> -->
              <!-- <b-nav-item href="/faqs" class="ml-2">FAQ's</b-nav-item> -->
              <b-nav-item href="/login" class="ml-2" v-if="!loginStatus"
                >Sign In
              </b-nav-item>
              <b-nav-item href="/" class="ml-2" v-else>Sign Out </b-nav-item>

              <a href="#lets_talk" class="ml-2 contactBtn">Contact Us</a>
            </b-navbar-nav>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginStatus: false,
    };
  },
  mounted() {
    var loginData = localStorage.getItem("LoginData");
    if (loginData != null) {
      this.loginStatus = true;
    }

    let routeName = this.$route.name;
    console.log(routeName);
    if (routeName === "Home") {
      document.getElementById("home").classList.add("activeItem");
    } else if (routeName === "about-us") {
      document.getElementById("about").classList.add("activeItem");
    } else if (routeName === "products") {
      document.getElementById("products").classList.add("activeItem");
    } else {
      const links = document.querySelectorAll(".navbar ul li");
      links.forEach((link) => {
        link.classList.remove("activeItem");
      });
    }
    var header = document.getElementById("header");
    window.onscroll = function() {
      if (
        document.body.scrollTop >= 20 ||
        document.documentElement.scrollTop >= 20
      ) {
        header.style.background = "#015EAB";
      } else {
        header.style.background = "rgba(1, 94, 171, 0.1)";
      }
    };
  },
  methods: {
    LogOut() {
      sessionStorage.clear("LoginData");
      console.log(sessionStorage.getItem("LoginData"));
    },
  },
};
</script>

<style scoped>
.activeItem a:after {
  color: #e88810;
  left: 0;
  right: auto;
  left: 11px;
  width: calc(100% - 22px) !important;
  border-radius: 5px;
}
</style>
<style lang="scss" scoped>
.header {
  z-index: 997;
  transition: all 0.3s;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  .navbar {
    height: 59px;
    padding: 10px 0;
    @media(min-width:1400px){
    height: 69px;

    }
    .navbar-toggler {
      .navbar-toggler-icon:focus,
      &:focus,
      &:active {
        border: none !important;
        outline: 0 !important;
        box-shadow: none;
      }
      img {
        height: 25px;
      }
    }
    .navbar-brand {
      height: 100%;
      max-width:100%;
      img {
        height: 100%;
      }
    }
    .navbar-collapse {
      .navbar-nav {
        .nav-link {
          color: #fff;
          padding: 12px 15px;
          position: relative;
          font-size: 16px;
          font-weight: 800 !important;
        }

        .nav-link:after {
          content: "";
          position: absolute;
          z-index: -1;
          right: 0;
          width: 0;
          bottom: -5px;
          background: #eb9f3f;
          height: 3px;
          transition-property: width;
          transition-duration: 0.3s;
          transition-timing-function: ease-out;
        }
        .nav-link:hover:after {
          color: #e88810;
          right: auto;
          left: 11px;
          width: calc(100% - 22px);
          border-radius: 5px;
        }
        .contactBtn {
          background: #eb9f3f;
          padding: 8px 15px;
          color: #fff;
          font-weight: bolder;
          border-radius: 50px;
          transition: ease-in-out 0.3s;
          cursor: pointer;
          letter-spacing: 1px;
          text-decoration: none;
        }
        .contactBtn:hover {
          color: #e88810;
          background: #fff;
        }
        .active a {
          // color: #e88810 !important;
          text-decoration: none;
          // border-bottom:4px solid #000;
        }
      }
    }
  }
  @media (max-width: 992px) {
    .navbar-nav {
      ul {
        border-radius: 20px;
        background: #fff;
        height: 82vh;
        z-index: 9999;
        .nav-item {
          padding: 10px;
          font-size: 16px;
        }
      }
      .nav-link {
        color: #000 !important;
      }
    }
  }
}
</style>
