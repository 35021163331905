import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
const module1 = {
    state: () => ({
        data: {},
        twilioId:"",
        user:{},
        auth: false,
    }),

    mutations: {
        setData: (state, data) => {
            state.data = Object.assign(state.data, data);
        },
        setTwilioId: (state, data) => {
            state.twilioId = data;
        },
        setAuth:(state , data) =>{
            state.auth = data
        }
    },

    actions: {
        setData: (context, data) => {
            context.commit('setData', data)
        },
        setTwilioId: (context, data) => {
            context.commit('setTwilioId', data)
        },
        setAuth:(context , data) =>{
            context.commit('setAuth' ,data)
        }

    },

    getters: {
        getData: (state) => {
            return state.data;
        },
        getTwilioId: (state) => {
            return state.twilioId;
        },
        getAuth:(state) =>{
            return state.auth
        }
    }
}

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        module1: module1
    },
    plugins: [createPersistedState()],
})

export default store